import Axios from "axios";
import querystring from "querystring";

const getBaseUrl = (): string => {
  if (process.browser) {
    return process.env.NEXT_PUBLIC_API;
  }
  return process.env.NEXT_API;
};

const getAllResponseHeaders = () => {
  if (!process.browser) return {};
  function parseHttpHeaders(httpHeaders) {
    return httpHeaders
      .split("\n")
      .map((x) => x.split(/: */, 2))
      .filter((x) => x[0])
      .reduce((ac, x) => {
        ac[x[0]] = x[1];
        return ac;
      }, {});
  }

  var req = new XMLHttpRequest();
  req.open("GET", document.location.href, false);
  req.send(null);
  var headers = parseHttpHeaders(req.getAllResponseHeaders());
  return headers;
};

const api = Axios.create({
  baseURL: `${getBaseUrl()}/api/`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(process.browser && {
      "x-country-origin":
        getAllResponseHeaders()["cloudfront-viewer-country"] || "in",
    }),
  },
});

export async function verifyToken(token: string): Promise<boolean> {
  try {
    await api.get("", { headers: { Authorization: token } });
    return true;
  } catch (e) {
    return false;
  }
}

export async function get(endPoint: string, headers?: any) {
  return await api.get(endPoint, { headers });
}

export async function getByQueryParam(
  endPoint: string,
  params: querystring.ParsedUrlQueryInput,
  headers?: any
) {
  return await api.get(`${endPoint}?${querystring.stringify(params)}`, {
    headers,
  });
}

export async function getById(
  endPoint: string,
  id: number | string,
  headers?: any
) {
  return await api.get(`${endPoint}/${id}/`, { headers });
}

export async function post(endPoint: string, payload: any, headers?: any) {
  return await api.post(endPoint, payload, { headers });
}

export async function put(endPoint: string, payload: any, headers?: any) {
  return await api.put(endPoint, payload, { headers });
}

export async function patch(endPoint: string, payload: any, headers?: any) {
  return await api.patch(endPoint, payload, { headers });
}

export async function deleteById(
  endPoint: string,
  id: string | number,
  headers?: any
) {
  return await api.delete(`${endPoint}${id}/`, { headers });
}

export async function deleteWithoutId(
  endPoint: string,
  payload: any,
  headers?: any
) {
  return await api({
    method: "delete",
    url: endPoint,
    data: payload,
    headers: headers,
  });
}
