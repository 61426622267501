import Constants from "constants/Constants";
import Head from "next/head";
import { FC, memo, ReactNode } from "react";

interface SEOWrapperProps {
  title?: string;
  description?: string;
  keywords?: string;
  canonicalUrl?: string;
  image?: string;
  scripts?: Array<any>;
  children?: ReactNode;
}

const SEOWrapper: FC<SEOWrapperProps> = ({
  title,
  description,
  keywords,
  canonicalUrl,
  image,
  scripts = [],
  children,
}) => {
  const defaultTitle =
    "Travel, Online Experiences, Long Stays & Retreats | BWT Experiences";
  const defaultDescription =
    "Perfect place to explore offline and online experiences, from weekend trips to experiencing yoga online or work from anywhere be it in hills or beaches, we’ve got it all covered. Explore now!";
  const defaultKeywords = "";
  return (
    <Head>
      <title>{title ? `${title} | BWT Experiences` : defaultTitle}</title>
      <meta name={"description"} content={description || defaultDescription} />
      <meta name={"keywords"} content={keywords || defaultKeywords} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:url" content={canonicalUrl} />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta property="og:image" content={image || Constants.LOGO_URL} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@bwtexperiences" />
      <meta name="twitter:creator" content="@bwtexperiences" />

      {scripts?.length &&
        scripts.map((it, i) => (
          <script
            key={i}
            type="application/ld+json"
            async
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(it),
            }}
          />
        ))}

      {children}
    </Head>
  );
};

export default memo(SEOWrapper);
