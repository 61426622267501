import { FC } from "react";

export const ChevronLeft = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
    />
  </svg>
);

export const ChevronRight = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
    />
  </svg>
);

export const ChevronDown = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
    />
  </svg>
);

export const ChevronUp = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"
    />
  </svg>
);

export const Instagram = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
    />
  </svg>
);

export const Facebook = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z"
    />
  </svg>
);
export const Twitter = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
    />
  </svg>
);
export const WhatsApp = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"
    />
  </svg>
);

export const Share = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      strokeWidth={"0"}
      d="M14,5V9C7,10 4,15 3,20C5.5,16.5 9,14.9 14,14.9V19L21,12L14,5M16,9.83L18.17,12L16,14.17V12.9H14C11.93,12.9 10.07,13.28 8.34,13.85C9.74,12.46 11.54,11.37 14.28,11L16,10.73V9.83Z"
    />
  </svg>
);
export const Location = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
    />
  </svg>
);

export const Transport = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M12,4H5A3,3 0 0,0 2,7V15A3,3 0 0,0 5,18L4,19V20H5L7,17.97L9,18V13H4V6H13V8H15V7A3,3 0 0,0 12,4M5,14A1,1 0 0,1 6,15A1,1 0 0,1 5,16A1,1 0 0,1 4,15A1,1 0 0,1 5,14M20.57,9.66C20.43,9.26 20.05,9 19.6,9H12.41C11.95,9 11.58,9.26 11.43,9.66L10,13.77V19.28C10,19.66 10.32,20 10.7,20H11.32C11.7,20 12,19.62 12,19.24V18H20V19.24C20,19.62 20.31,20 20.69,20H21.3C21.68,20 22,19.66 22,19.28V17.91L22,13.77L20.57,9.66M12.41,10H19.6L20.63,13H11.38L12.41,10M12,16A1,1 0 0,1 11,15A1,1 0 0,1 12,14A1,1 0 0,1 13,15A1,1 0 0,1 12,16M20,16A1,1 0 0,1 19,15A1,1 0 0,1 20,14A1,1 0 0,1 21,15A1,1 0 0,1 20,16Z"
    />
  </svg>
);

export const Accommodation = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z"
    />
  </svg>
);

export const Camp = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M2,16.25C2,16.25 4,3.75 12,3.75C20,3.75 22,16.25 22,16.25C22,16.25 20,20.25 12,20.25C4,20.25 2,16.25 2,16.25M3.35,15.65C3.35,15.65 4.3,19 12,19C17,19 20,17.8 20.65,15.85C21.3,13.9 15.65,7.6 14.65,7.6C13.65,7.6 11.2,12 10.45,12C8.45,12 8.9,10 7.15,10C5.4,10 3.35,15.65 3.35,15.65Z"
    />
  </svg>
);

export const Calendar = ({
  height = 24,
  width = 24,
  fill = "#000",
  ...props
}) => (
  <svg height={height} width={width} {...props} viewBox="0 0 24 24">
    <path
      fill={fill}
      d="M15,13H16.5V15.82L18.94,17.23L18.19,18.53L15,16.69V13M19,8H5V19H9.67C9.24,18.09 9,17.07 9,16A7,7 0 0,1 16,9C17.07,9 18.09,9.24 19,9.67V8M5,21C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H6V1H8V3H16V1H18V3H19A2,2 0 0,1 21,5V11.1C22.24,12.36 23,14.09 23,16A7,7 0 0,1 16,23C14.09,23 12.36,22.24 11.1,21H5M16,11.15A4.85,4.85 0 0,0 11.15,16C11.15,18.68 13.32,20.85 16,20.85A4.85,4.85 0 0,0 20.85,16C20.85,13.32 18.68,11.15 16,11.15Z"
    />
  </svg>
);

export const Stay = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M19,7H11V14H3V5H1V20H3V17H21V20H23V11A4,4 0 0,0 19,7M7,13A3,3 0 0,0 10,10A3,3 0 0,0 7,7A3,3 0 0,0 4,10A3,3 0 0,0 7,13Z"
    />
  </svg>
);

export const Tick = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="25"
    height="25"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#000"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <polyline points="9 11 12 14 20 6" />
    <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9" />
  </svg>
);

export const Close = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z"
    />
  </svg>
);

export const EmptyTick = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
    />
  </svg>
);

export const EmptyClose: FC<any> = ({
  fill,
  height,
  width,
  size,
  ...props
}) => (
  <svg
    {...props}
    height={size || height || 24}
    width={size || width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={fill || "#000000"}
      d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
    />
  </svg>
);

export const Cash = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M2,17H22V21H2V17M6.25,7H9V6H6V3H14V6H11V7H17.8C18.8,7 19.8,8 20,9L20.5,16H3.5L4.05,9C4.05,8 5.05,7 6.25,7M13,9V11H18V9H13M6,9V10H8V9H6M9,9V10H11V9H9M6,11V12H8V11H6M9,11V12H11V11H9M6,13V14H8V13H6M9,13V14H11V13H9M7,4V5H13V4H7Z"
    />
  </svg>
);

export const AboutLocation = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M14,6.11L8,4V15.89L9,16.24V16.5C9,17.14 9.09,17.76 9.26,18.34L8,17.9L2.66,19.97L2.5,20A0.5,0.5 0 0,1 2,19.5V4.38C2,4.15 2.15,3.97 2.36,3.9L8,2L14,4.1L19.34,2H19.5A0.5,0.5 0 0,1 20,2.5V11.81C18.83,10.69 17.25,10 15.5,10C15,10 14.5,10.06 14,10.17V6.11Z"
    />
  </svg>
);

export const Watch = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z"
    />
  </svg>
);

export const Search = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
    />
  </svg>
);

export const Menu = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
    />
  </svg>
);

export const HamBurgerMenu = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-menu"
    width="25"
    height="25"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="#2c3e50"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <line x1="4" y1="8" x2="20" y2="8" />
    <line x1="4" y1="16" x2="20" y2="16" />
  </svg>
);

export const Whatsapp = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M16.75,13.96C17,14.09 17.16,14.16 17.21,14.26C17.27,14.37 17.25,14.87 17,15.44C16.8,16 15.76,16.54 15.3,16.56C14.84,16.58 14.83,16.92 12.34,15.83C9.85,14.74 8.35,12.08 8.23,11.91C8.11,11.74 7.27,10.53 7.31,9.3C7.36,8.08 8,7.5 8.26,7.26C8.5,7 8.77,6.97 8.94,7H9.41C9.56,7 9.77,6.94 9.96,7.45L10.65,9.32C10.71,9.45 10.75,9.6 10.66,9.76L10.39,10.17L10,10.59C9.88,10.71 9.74,10.84 9.88,11.09C10,11.35 10.5,12.18 11.2,12.87C12.11,13.75 12.91,14.04 13.15,14.17C13.39,14.31 13.54,14.29 13.69,14.13L14.5,13.19C14.69,12.94 14.85,13 15.08,13.08L16.75,13.96M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C10.03,22 8.2,21.43 6.65,20.45L2,22L3.55,17.35C2.57,15.8 2,13.97 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12C4,13.72 4.54,15.31 5.46,16.61L4.5,19.5L7.39,18.54C8.69,19.46 10.28,20 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z"
    />
  </svg>
);

export const Sun = (props) => (
  <svg
    height={props.height || 24}
    width={props.width || 24}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.fill || "#000000"}
      d="M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,2L14.39,5.42C13.65,5.15 12.84,5 12,5C11.16,5 10.35,5.15 9.61,5.42L12,2M3.34,7L7.5,6.65C6.9,7.16 6.36,7.78 5.94,8.5C5.5,9.24 5.25,10 5.11,10.79L3.34,7M3.36,17L5.12,13.23C5.26,14 5.53,14.78 5.95,15.5C6.37,16.24 6.91,16.86 7.5,17.37L3.36,17M20.65,7L18.88,10.79C18.74,10 18.47,9.23 18.05,8.5C17.63,7.78 17.1,7.15 16.5,6.64L20.65,7M20.64,17L16.5,17.36C17.09,16.85 17.62,16.22 18.04,15.5C18.46,14.77 18.73,14 18.87,13.21L20.64,17M12,22L9.59,18.56C10.33,18.83 11.14,19 12,19C12.82,19 13.63,18.83 14.37,18.56L12,22Z"
    />
  </svg>
);

export const Heart_Empty = (props) => (
  <svg
    className={props.className || ""}
    width={props.width || "34"}
    height={props.height || "31"}
    viewBox="0 0 34 31"
    version="1.1"
  >
    <g id="Page-1" stroke="none" fill="none" fillRule="evenodd">
      <g id="heart" fill="#7B7B7B" fillRule="nonzero">
        <path
          strokeWidth="4"
          d="M24.0881938,0 C22.5029526,0 20.9877443,0.365307765 19.5849113,1.08584632 C18.629967,1.57623536 17.7526969,2.22778543 17,3.00082289 C16.247233,2.22778543 15.370033,1.57623536 14.4150887,1.08584632 C13.0121856,0.365307765 11.4970474,0 9.91173608,0 C4.44642887,0 0,4.46959406 0,9.96344513 C0,13.8546352 2.04441649,17.9873907 6.07639588,22.2470597 C9.4428866,25.8036662 13.5645278,28.7684093 16.4292907,30.6292662 L17,31 L17.5707093,30.6292662 C20.4354722,28.7684797 24.5571134,25.8036662 27.9236742,22.2470597 C31.9556536,17.9873907 34,13.8546352 34,9.96344513 C34,4.46959406 29.5535711,0 24.0881938,0 Z M26.1496024,20.7172139 C23.22108,23.8517602 19.6538753,26.5165345 17,28.3 C14.3461247,26.516465 10.77892,23.8517602 7.85046588,20.7172139 C4.30011529,16.9172038 2.5,13.3251163 2.5,10.0407434 C2.5,5.77249017 5.90964941,2.3 10.1006612,2.3 C12.5135294,2.3 14.7300165,3.428773 16.1816541,5.39681161 L17,6.50626559 L17.8183459,5.39681161 C19.2699835,3.428773 21.4864706,2.3 23.8993388,2.3 C28.0903506,2.3 31.5,5.77249017 31.5,10.0407434 C31.5,13.3251163 29.6998165,16.9172038 26.1496024,20.7172139 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export const Heart_Filled = (props) => (
  <svg
    className={props.className || ""}
    width={props.width || "34"}
    height={props.height || "31"}
    viewBox="0 0 34 31"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Heart-2" fill="#e02020" fillRule="nonzero">
        <path
          d="M24.0881938,0 C22.5029526,0 20.9877443,0.365307765 19.5849113,1.08584632 C18.629967,1.57623536 17.7526969,2.22778543 17,3.00082289 C16.247233,2.22778543 15.370033,1.57623536 14.4150887,1.08584632 C13.0121856,0.365307765 11.4970474,0 9.91173608,0 C4.44642887,0 0,4.46959406 0,9.96344513 C0,13.8546352 2.04441649,17.9873907 6.07639588,22.2470597 C9.4428866,25.8036662 13.5645278,28.7684093 16.4292907,30.6292662 L17,31 L17.5707093,30.6292662 C20.4354722,28.7684797 24.5571134,25.8036662 27.9236742,22.2470597 C31.9556536,17.9873907 34,13.8546352 34,9.96344513 C34,4.46959406 29.5535711,0 24.0881938,0 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export const Copy: FC<any> = ({
  className,
  style,
  width,
  height,
  color,
  ...props
}) => (
  <svg
    className={className || ""}
    style={style}
    width={width || 24}
    height={height || 24}
    {...props}
    viewBox="0 0 24 24"
  >
    <path
      style={{ fill: color || "#000000" }}
      d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
    />
  </svg>
);

export const Back = (props) => (
  <svg
    className={props.className || ""}
    style={props.style}
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
  >
    <path
      style={{ fill: props.color || "#000000" }}
      d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
    />
  </svg>
);

export const Heart = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-heart"
    width="25"
    height="25"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="#2c3e50"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M12 20l-7 -7a4 4 0 0 1 6.5 -6a.9 .9 0 0 0 1 0a4 4 0 0 1 6.5 6l-7 7" />
  </svg>
);
export const Booking = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="25"
    height="25"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="#2c3e50"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M11 3L20 12a1.5 1.5 0 0 1 0 2L14 20a1.5 1.5 0 0 1 -2 0L3 11v-4a4 4 0 0 1 4 -4h4" />
    <circle cx="9" cy="9" r="2" />
  </svg>
);
export const User = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="25"
    height="25"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="#2c3e50"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <circle cx="12" cy="7" r="4" />
    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
  </svg>
);
export const SearchNew = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width={props.width || "25"}
    height={props.height || "25"}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="10" cy="10" r="7" />
    <line x1="21" y1="21" x2="15" y2="15" />
  </svg>
);
export const Plus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width={props.width || "25"}
    height={props.height || "25"}
    viewBox="0 0 24 24"
    strokeWidth="3"
    stroke={props.fill || "#1ab277"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <line x1="12" y1="5" x2="12" y2="19" />
    <line x1="5" y1="12" x2="19" y2="12" />
  </svg>
);
export const Minus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width={props.width || "25"}
    height={props.height || "25"}
    viewBox="0 0 24 24"
    strokeWidth="3"
    stroke={props.fill || "#1ab277"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <line x1="5" y1="12" x2="19" y2="12" />
  </svg>
);

export const ChevronDownNew = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="25"
    height="25"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <polyline points="6 9 12 15 18 9" />
  </svg>
);

export const Devices = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <rect x="13" y="8" width="8" height="12" rx="1" />
    <path d="M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9" />
    <line x1="16" y1="9" x2="18" y2="9" />
  </svg>
);

export const Compass = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <polyline points="8 16 10 10 16 8 14 14 8 16" />
    <circle cx="12" cy="12" r="9" />
  </svg>
);

export const Bike = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <circle cx="5" cy="18" r="3" />
    <circle cx="19" cy="18" r="3" />
    <polyline points="12 19 12 15 9 12 14 8 16 11 19 11" />
    <circle cx="17" cy="5" r="1" />
  </svg>
);

export const Cast = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <line x1="3" y1="19" x2="3.01" y2="19" />
    <path d="M7 19a4 4 0 0 0 -4 -4" />
    <path d="M11 19a8 8 0 0 0 -8 -8" />
    <path d="M15 19h3a3 3 0 0 0 3 -3v-8a3 3 0 0 0 -3 -3h-12a3 3 0 0 0 -2.8 2" />
  </svg>
);

export const Maps = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <line x1="18" y1="6" x2="18" y2="6.01" />
    <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />
    <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />
    <line x1="9" y1="4" x2="9" y2="17" />
    <line x1="15" y1="15" x2="15" y2="20" />
  </svg>
);

export const Tag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="25"
    height="25"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M11 3L20 12a1.5 1.5 0 0 1 0 2L14 20a1.5 1.5 0 0 1 -2 0L3 11v-4a4 4 0 0 1 4 -4h4" />
    <circle cx="9" cy="9" r="2" />
  </svg>
);

export const WishList = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="25"
    height="25"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <rect x="3" y="8" width="18" height="4" rx="1" />
    <line x1="12" y1="8" x2="12" y2="21" />
    <path d="M19 12v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-7" />
    <path d="M7.5 8a2.5 2.5 0 0 1 0 -5a4.8 8 0 0 1 4.5 5a4.8 8 0 0 1 4.5 -5a2.5 2.5 0 0 1 0 5" />
  </svg>
);
export const Logout = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="25"
    height="25"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
    <path d="M7 12h14l-3 -3m0 6l3 -3" />
  </svg>
);
export const ShareNew: FC<any> = ({ fill, size = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <circle cx="6" cy="12" r="3" />
    <circle cx="18" cy="6" r="3" />
    <circle cx="18" cy="18" r="3" />
    <line x1="8.7" y1="10.7" x2="15.3" y2="7.3" />
    <line x1="8.7" y1="13.3" x2="15.3" y2="16.7" />
  </svg>
);
export const SANITISED_ROOM = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="50"
    height="50"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6" />
    <circle cx="7" cy="10" r="1" />
  </svg>
);

export const SANITISED_VEHICLE = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="50"
    height="50"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <circle cx="6" cy="17" r="2" />
    <circle cx="18" cy="17" r="2" />
    <path d="M4 17h-2v-11a1 1 0 0 1 1 -1h14a5 7 0 0 1 5 7v5h-2m-4 0h-8" />
    <polyline points="16 5 17.5 12 22 12" />
    <line x1="2" y1="10" x2="17" y2="10" />
    <line x1="7" y1="5" x2="7" y2="10" />
    <line x1="12" y1="5" x2="12" y2="10" />
  </svg>
);

export const MEDICAL = (props) => (
  <svg
    id="Capa_1"
    stroke={props.fill || "#2c3e50"}
    enableBackground="new 0 0 510.573 510.573"
    height="50"
    viewBox="0 0 510.573 510.573"
    width="50"
  >
    <g>
      <path d="m484.471 301.554-38.394 38.394v-175.638l60.102-60.103c5.858-5.857 5.858-15.355 0-21.213-5.857-5.857-15.355-5.857-21.213 0l-59.092 59.093-68.497-10.829c-67.885-13.535-136.715-13.535-204.6 0l-68.497 10.829-58.673-58.674c-5.857-5.857-15.355-5.857-21.213 0s-5.858 15.355 0 21.213l59.684 59.684v175.638l-38.394-38.394c-5.857-5.857-15.355-5.857-21.213 0s-5.858 15.355 0 21.213l64 64c1.423 1.423 2.963 2.412 4.608 3.131.941.41 51.847 19.58 52.067 19.654 87.06 29.474 170.338 30.308 259.861 0 .101-.034 51.744-19.402 53.257-20.233 2.779-1.514-2.256 3.123 67.419-66.552 5.858-5.857 5.858-15.355 0-21.213-5.857-5.857-15.355-5.857-21.212 0zm-109.322 79.663c-81.91 27.68-158.232 27.68-240.143 0l-40.929-15.432v-194.872c71.11-11.242 63.822-10.083 64.28-10.175 64.18-12.836 129.26-12.836 193.439 0 .458.092-6.83-1.067 64.28 10.175v194.873z" />
      <path d="m367.077 223.161h-224c-8.284 0-15-6.716-15-15s6.716-15 15-15h224c8.284 0 15 6.716 15 15s-6.715 15-15 15z" />
      <path d="m367.077 287.161h-224c-8.284 0-15-6.716-15-15s6.716-15 15-15h224c8.284 0 15 6.716 15 15s-6.715 15-15 15z" />
      <path d="m367.077 351.161h-224c-8.284 0-15-6.716-15-15s6.716-15 15-15h224c8.284 0 15 6.716 15 15s-6.715 15-15 15z" />
    </g>
  </svg>
);

export const TEMPERATURE_CHECKS = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="50"
    height="50"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M8 13.5a4 4 0 1 0 4 0v-8.5a2 2 0 0 0 -4 0v8.5" />
    <line x1="8" y1="9" x2="12" y2="9" />
    <line x1="16" y1="9" x2="22" y2="9" />
    <line x1="19" y1="6" x2="19" y2="12" />
  </svg>
);

export const SAFETY_AMENITIES = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width="50"
    height="50"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M9 12l2 2l4 -4" />
    <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
  </svg>
);

export const Live = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width={props.width || "50"}
    height={props.height || "50"}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
    <rect x="3" y="6" width="12" height="12" rx="2" />
  </svg>
);
export const Device = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width={props.width || "50"}
    height={props.height || "50"}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <rect x="13" y="8" width="8" height="12" rx="1" />
    <path d="M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9" />
    <line x1="16" y1="9" x2="18" y2="9" />
  </svg>
);
export const Time = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width={props.width || "50"}
    height={props.height || "50"}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <circle cx="12" cy="12" r="9" />
    <polyline points="12 7 12 12 15 15" />
  </svg>
);

export const TimeNew = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width={props.width || "50"}
    height={props.height || "50"}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <circle cx="12" cy="12" r="9" />
    <polyline points="12 7 12 12 15 15" />
  </svg>
);

export const Language = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width={props.width || "50"}
    height={props.height || "50"}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M5 7h7m-2 -2v2a5 8 0 0 1 -5 8m1 -4a7 4 0 0 0 6.7 4" />
    <path d="M11 19l4 -9l4 9m-.9 -2h-6.2" />
  </svg>
);

export const CircleCheck = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width={props.width || "50"}
    height={props.height || "50"}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <circle cx="12" cy="12" r="9" />
    <path d="M9 12l2 2l4 -4" />
  </svg>
);

export const Verified = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width={props.width || "50"}
    height={props.height || "50"}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M9 12l2 2l4 -4" />
    <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
  </svg>
);

export const Bell = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width={props.width || "50"}
    height={props.height || "50"}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
    <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
  </svg>
);

export const Rate = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-tag"
    width={props.width || "50"}
    height={props.height || "50"}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={props.fill || "#2c3e50"}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M4 5h2" />
    <path d="M5 4v2" />
    <path d="M11.5 4l-.5 2" />
    <path d="M18 5h2" />
    <path d="M19 4v2" />
    <path d="M15 9l-1 1" />
    <path d="M18 13l2 -.5" />
    <path d="M18 19h2" />
    <path d="M19 18v2" />
    <path d="M14 16.518l-6.518 -6.518l-4.39 9.58a1.003 1.003 0 0 0 1.329 1.329l9.579 -4.39z" />
  </svg>
);
