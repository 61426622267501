import {
  Device,
  Language,
  Live,
  MEDICAL,
  SAFETY_AMENITIES,
  SANITISED_ROOM,
  SANITISED_VEHICLE,
  TEMPERATURE_CHECKS,
  TimeNew,
} from "common/Icons/Icons";

import Utils from "../utils/Utils";

export const REVALIDATE = 1 * 60; // Revalidate in 1 mins
export const ALL = "all";
export const SITE_URL = "https://www.bwtexperiences.com";
export const PAGE_LIMIT = 16;

const Constants = {
  LOGO_URL: "https://cdn.bwtexperiences.com/image-bank/new-bwt-logo_orig.png",
  AS_SEEN_ON_IMAGES: [
    `${Utils.cdnUrl("webImages/medium.png")}`,
    `${Utils.cdnUrl("webImages/tripadvisor.png")}`,
    `${Utils.cdnUrl("webImages/yourstory.png")}`,
    `${Utils.cdnUrl("webImages/tripoto.png")}`,
  ],
  VALUE_PROPOSITIONS_IMAGES: [
    {
      url: Utils.cdnUrl("webImages/icons/27_7+On+Trip+assistance.svg"),
      title: "24x7 Assistance",
    },
    {
      url: Utils.cdnUrl(
        "webImages/icons/35%2B+Destinations+to+choose+from.svg"
      ),
      title: "Amazing Experiences",
    },
    {
      url: Utils.cdnUrl("webImages/icons/Best+prices+%26+offers.svg"),
      title: "Unbeatable Prices with Best Offers",
    },
    {
      url: Utils.cdnUrl("webImages/icons/Certified+trip+leaders.svg"),
      title: "Certified Professionals",
    },
    {
      url: Utils.cdnUrl("webImages/icons/Exceptional+Itineraries.svg"),
      title: "Exceptional & Curated Itineraries",
    },
    {
      url: Utils.cdnUrl("webImages/icons/Trips+for+every+weekend.svg"),
      title: "experience for every day",
    },
  ],
  RUPEE_SYMBOL: "₹",
  YOGA_STRIP: [
    {
      name: "Live Classes",
      icon: <Live height={35} />,
    },
    {
      name: "time",
      icon: <TimeNew height={35} />,
    },
    {
      name: "Join From Anywhere",
      icon: <Device height={35} />,
    },
    {
      name: "Multi Language Suport",
      icon: <Language height={35} />,
    },
  ],
  WORK_FROM_ANYWHERE_STRIP: [
    {
      name: "Comfortable Stays",
      url: Utils.cdnUrl("webImages/bed.svg"),
    },
    {
      name: "High Speed WiFi",
      url: Utils.cdnUrl("webImages/internet.svg"),
    },
    {
      name: "Workstation",
      url: Utils.cdnUrl("webImages/deck.svg"),
    },

    {
      name: "Power backup",
      url: Utils.cdnUrl("webImages/service.svg"),
    },
    {
      name: "Sanitised rooms",
      url: Utils.cdnUrl("webImages/toiletries.svg"),
    },
  ],
  ONLINE_EXPERIENCES_FAQ: [
    {
      question:
        "Are these live sessions? Can I ask questions during the sessions?",
      answer:
        "Yes, these are live and interactive sessions. of course, you can ask questions but we request you to ask you questions at the starting or at the end of the sessions.",
    },
    {
      question: "Will I get a link after booking the session?",
      answer:
        "Yes, you will receive an e-mail right after booking the session with all the joining details and joining instructions.",
    },
    {
      question: "What if I miss one class, can it be rescheduled?",
      answer:
        "No, in case you miss any classes the class cannot be rescheduled as it is a monthly subscription (can be done in one-on-one sessions)",
    },
    {
      question: "Can I get a trial class?",
      answer:
        "Sorry but at the moment we do not offer any trial classes as our prices are already very affordable.",
    },
    {
      question:
        "I have got a medical condition, can I get the sessions accordingly?",
      answer:
        "In case you have a medical condition, please tell us before joining the class so that we can inform the yogi/yogini. The instructor will make sure that you’ve been provided with custom asanas.",
    },
    {
      question:
        "Will I be joining a new batch or an on-going one or when does the batch starts?",
      answer:
        "The classes happens on regular basis which can be attended at any time.",
    },
    {
      question:
        "What will be the maximum and minimum strength of a group session?",
      answer:
        "The maximum and the minimum number of people in the group will be 30 and 60 respectively.",
    },
    {
      question: "Do I need to keep my camera/webcam on? ",
      answer:
        "It completely depends upon your choice, keep it on if you will comfortable otherwise keep it turned off. ",
    },
  ],
  WEEKEND_TRIPS_FAQ: [
    {
      question: "I’m traveling solo, can I join the trip?",
      answer:
        "Yes, absolutely! As a matter of fact 40% of our groups comprises of solo travellers.",
    },
    {
      question: "Are these trips couples friendly?",
      answer:
        "Yes, all our trips are couple friendly and the trips leaders are engaging who will keep engaging the group in ice breaking activities.",
    },
    {
      question:
        "How is the quality of food and what type of food are we going to get?",
      answer:
        "The food served during the trip is delicious home cooked 100% vegetarian (Non-veg available on extra charges)",
    },
    {
      question:
        "What precautions are taken to ensure the safety of the group from COVID exposure?",
      answer:
        "The trips leader, commute partners and our hosts are taking all the required measure to the safety of the travellers from sanitising the stays and vehicles regularly to doing the regular temperature checks of the staff.",
    },
    {
      question: "What do I need to carry while on the trip?",
      answer:
        "A list is always shared with you of what to carry once you book the trip.",
    },
    {
      question: "Are there going to be bonfires nights?",
      answer: "Well you can say that any trip is incomplete without a bonfire",
    },
    {
      question:
        "What type of accommodation will be provided if I’m travelling solo?",
      answer:
        "It completely depend on what type of accommodation you are choosing while making the booking (double occupancy, triple, quadruple, etc.) You’ll be sharing the room/camp with your fellow travellers.",
    },
    {
      question: "Can I can reschedule my trip?",
      answer:
        "For rescheduling queries, kindly reach out to us at hello@bwtexperiences.com. Although, we do not promise any rescheduling but we will surely try our best to accommodate your trips as per your need.",
    },
    {
      question: "Can I cancel my trip?",
      answer:
        "Cancellation policy is mentioned on the package details page, incase of cancellation kindly refer to the same.",
    },
    {
      question: "Can I bring my child/children on the trip?",
      answer:
        "Our trips usually have an intense trekking included so it is is advised to not to bring any ward with you on the trips.",
    },
  ],
  WFA_FAQ: [
    {
      question: "How much internet speed will I get?",
      answer:
        "The internet speed completely depends upon the numbers of user connected, usually the speed ranges between 5 mbps to 30 mbps.",
    },
    {
      question:
        "What precautions are taken to ensure the safety of the group from COVID exyure?",
      answer:
        "Yes, you will receive an e-mail right after booking the session with all the joining details and joining instructions.",
    },
    {
      question: "Will I get a dedicated work station for my work?",
      answer:
        "No, the work stations or common area are free for all and are available on first come first serve basis.",
    },
    {
      question: "Are meals included in the price?",
      answer:
        "No, meals are not included in the prices but you can get homely meals at very affordable prices starting at 150 Rs a day.",
    },
    {
      question: "With whom will I be sharing the dorm with?",
      answer:
        "If you are booking a dorm than you will be sharing it  with people just like you, entrepreneurs, freelancers, remote employees, traveling enthusiasts, etc.",
    },
    {
      question:
        "Is transport included in the price? Can it be arranged on request?",
      answer:
        "No, the transport is not included in the price as most of our user travels on their own but if you need need we can arrange the transport as well.",
    },
  ],
  COVID_STRIP: [
    {
      icon: <TEMPERATURE_CHECKS />,
      title: "Regular temperature checks",
    },
    {
      icon: <MEDICAL />,
      title: "Mandatory mask",
    },
    {
      icon: <SANITISED_ROOM />,
      title: "Regular room sanitisation",
    },
    {
      icon: <SAFETY_AMENITIES />,
      title: "Safety amenities available",
    },
    {
      icon: <SANITISED_VEHICLE />,
      title: "Sanitised vehicles",
    },
  ],
  BASKET: [
    {
      question:
        "Are these live sessions? Can I ask questions during the sessions?",
      answer:
        "Yes, these are live and interactive sessions. of course, you can ask questions but we request you to ask you questions at the starting or at the end of the sessions.",
    },
    {
      question: "Will I get a link after booking the session?",
      answer:
        "Yes, you will receive an e-mail and WhatsApp message right after booking the session with all the joining details and instructions.",
    },
    {
      question: "What if I miss one class, can it be rescheduled?",
      answer:
        "No, in case you miss any classes the class cannot be rescheduled as it is a monthly subscription (can be done in one-on-one sessions)",
    },
    {
      question: "Can I get a trial class?",
      answer:
        "Yes trial sessions are only available with out Subscription plan. You can cancel the subscription any time from My Membership section",
    },
    {
      question:
        "I have got a medical condition, can I get the sessions accordingly?",
      answer:
        "In case you have a medical condition, please tell us before joining the class so that we can inform the yogi/yogini. The instructor will make sure that you’ve been provided with custom asanas.",
    },
    {
      question:
        "Will I be joining a new batch or an on-going one or when does the batch starts?",
      answer:
        "The classes happens on regular basis which can be attended at any time.",
    },
    {
      question:
        "What will be the maximum and minimum strength of a group session?",
      answer:
        "The maximum and the minimum number of people in the group will be 15 and 25 respectively.",
    },
    {
      question: "Do I need to keep my camera/webcam on? ",
      answer:
        "It completely depends upon your choice, keep it on if you will comfortable otherwise keep it turned off. ",
    },
    {
      question: "Can I pause my sessions/subscriptions in between?",
      answer:
        "Unfortunately, this is a monthly subscription which you can cancel any time but cannot pause.",
    },
    {
      question: "Can I cancel my subscription?",
      answer:
        "Yes, you can cancel your subscription any time from My Membership section. Also your subscription will be active until the expiry date",
    },
  ],
};

export default Constants;
