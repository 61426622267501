import { Identify, Page, Track } from "./GlobalTypes";

export default class Analytics {

	static shouldSendAnalyticsEvents = () => process.env.NEXT_PUBLIC_ENV === "production"

	static identify: Identify = (userId, traits) => {
		if (!traits) traits = {};
		if (Analytics.shouldSendAnalyticsEvents()) {
			window.analytics.identify(userId, traits);
		} else {
			console.log('IDENTIFY ->', userId, traits)
		}
	}

	static track: Track = (event, payload) => {
		if (!payload) payload = {};
		if (Analytics.shouldSendAnalyticsEvents()) {
			window.analytics.track(event, payload);
		}
		else {
			console.log('TRACK ->', event, payload)
		}
	};

	static page: Page = (title, properties) => {
		if (Analytics.shouldSendAnalyticsEvents())
			window.analytics.page(title, properties);
		else {
			console.log('PAGE ->',  title, properties)
		}
	}
}
