import {
  ComponentType,
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { getToken, removeToken } from "utils/CookieUtils";

import { IUser } from "./types";
import { useGetUser } from "utils/customHooks";
import { useRouter } from "next/router";

const AuthContext = createContext({});

export interface AuthProps {
  isAuthenticated: boolean;
  userData: IUser;
  getUserData: () => Promise<void>;
  userDataLoading: boolean;
  userDataError: boolean;
  onLogout: () => void;
}

const AuthProvider: FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const router = useRouter();

  const [
    userData,
    { loading: userDataLoading, error: userDataError },
    getUserData,
  ] = useGetUser(
    !!getToken(),
    (res) => {
      setIsAuthenticated(true);
      return res;
    },
    (err) => {
      setIsAuthenticated(false);
      removeToken();
      return err;
    }
  );

  const onLogout = (): void => {
    setIsAuthenticated(false);
    removeToken();
    router.push("/login");
  };

  const contextObject: AuthProps = {
    isAuthenticated,
    userData,
    getUserData,
    userDataLoading,
    userDataError,
    onLogout,
  };

  useEffect(() => {
    if (getToken()) getUserData();
  }, []);

  return (
    <AuthContext.Provider value={contextObject}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthProps {
  return useContext(AuthContext) as AuthProps;
}

export const withAuth = <P extends object>(
  WrapperComponent: ComponentType<P>
): FC<P> => {
  const authHoc = (props: any) => {
    const authData = useContext(AuthContext);
    return <WrapperComponent auth={authData as AuthProps} {...props} />;
  };

  return authHoc;
};

export default AuthProvider;
