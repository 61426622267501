import { Dispatch, useState } from "react";
import { deleteWithoutId, get, patch, post, put } from "./apiService";

import { AxiosResponse } from "axios";
import { getToken } from "utils/CookieUtils";
import querystring from "querystring";
import { useStateReducer } from "utils/customHooks";

export type FetchCallbackType = (data: any, headers?: any, args?: any) => any;

interface FetchOptions {
  beforeFetch?: FetchCallbackType;
  onSuccess?: FetchCallbackType;
  onError?: FetchCallbackType;
  method?: "GET" | "POST" | "PUT" | "DELETE" | "UPDATE" | "PATCH";
  payload?: Object;
  headers?: any;
  initialLoading?: boolean;
}
export type OnFetchType = (args?: {
  payload?: Object;
  params?: Object;
  queryParams?: querystring.ParsedUrlQueryInput;
  args?: any;
}) => Promise<void>;

export type FetchReturnType = [
  data: any,
  state: { loading: boolean; error: boolean; errorRes: any },
  onFetch: OnFetchType,
  setData: Dispatch<any>
];

type FetchType<P extends any[] = []> = (
  endPoint: string,
  initialState: any,
  options?: FetchOptions,
  ...args: P
) => FetchReturnType;

const useFetch: FetchType = (endPoint, initialState, options) => {
  const {
    beforeFetch,
    onSuccess,
    onError,
    method = "GET",
    headers,
    initialLoading,
  } = options;
  const [data, setData] = useState(initialState);
  const [state, setState] = useStateReducer({
    loading: initialLoading || false,
    error: false,
    errorRes: null,
  });

  const callApi = (
    payload?: Object,
    params?: Object,
    queryParams?: querystring.ParsedUrlQueryInput
  ): Promise<AxiosResponse<any>> => {
    let apiEndPoint = endPoint;
    if (params) {
      Object.keys(params).forEach((key) => {
        apiEndPoint = apiEndPoint.replace(`:${key}`, params[key]);
      });
    }
    const apiEndPointwithQuery = `${apiEndPoint}${
      queryParams ? `?${querystring.stringify(queryParams)}` : ""
    }`;
    const token = getToken();

    const _headers = { ...headers, ...(token && { Authorization: token }) };

    switch (method) {
      case "PUT":
        return put(apiEndPointwithQuery, payload, _headers);
      case "POST":
        return post(apiEndPointwithQuery, payload, _headers);
      case "PATCH":
        return patch(apiEndPointwithQuery, payload, _headers);
      case "DELETE":
        return deleteWithoutId(apiEndPointwithQuery, payload, _headers);
      default:
        return get(apiEndPointwithQuery, _headers);
    }
  };

  const onFetch = async (args?: {
    payload?: Object;
    params?: Object;
    queryParams?: querystring.ParsedUrlQueryInput;
    args?: any;
  }) => {
    setState({ loading: true });
    beforeFetch?.(args);
    try {
      const { data: res, headers } = await callApi(
        args?.payload,
        args?.params,
        args?.queryParams
      );
      setData(onSuccess ? onSuccess(res, headers, args?.args) : res);
      setState({
        loading: false,
      });
    } catch (e) {
      setState({
        loading: false,
        error: true,
        errorRes: onError ? onError(e) : e,
      });
    }
  };

  return [data, state, onFetch, setData];
};

export const useGet: FetchType = (endPoint, initialState, options) =>
  useFetch(endPoint, initialState, { ...options, method: "GET" });

export const useGetById: FetchType<[number]> = (
  endPoint,
  initialState,
  options,
  id
) =>
  useFetch(`${endPoint}/${id}/`, initialState, { ...options, method: "GET" });

export const usePost: FetchType = (endPoint, initialState, options) =>
  useFetch(endPoint, initialState, { ...options, method: "POST" });

export const usePatch: FetchType = (endPoint, initialState, options) =>
  useFetch(endPoint, initialState, { ...options, method: "PATCH" });

export const useDelete: FetchType = (endPoint, initialState, options) =>
  useFetch(endPoint, initialState, { ...options, method: "DELETE" });

export const usePut: FetchType = (endPoint, initialState, options) =>
  useFetch(endPoint, initialState, { ...options, method: "PUT" });

export default useFetch;
