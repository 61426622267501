// Only to use at client side
import Cookies from "js-cookie";

const TOKEN = "bwt_token";

export const getToken = () => Cookies.get(TOKEN);

export const removeToken = () => Cookies.remove(TOKEN);

export const setToken = (token: string) =>
  Cookies.set(TOKEN, `Bearer ${token}`, { expires: 30 });
