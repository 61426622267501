import Lockr from "lockr";
import { NextRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";
import toast from "react-hot-toast";
export default class Utils {
  //Message
  static successMessage(msg: string) {
    toast.success(msg);
  }
  static errorMessage(msg: string) {
    toast.error(msg);
  }

  static slugString(string) {
    if (!string) return;
    const a =
      "àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;";
    const b =
      "aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }

  static convertCommaStringToArray(string) {
    let stringArr = string.split(",");
    return stringArr;
  }

  static truncateString = (string, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (string.length > length) {
      return string.substring(0, length - ending.length) + ending;
    } else {
      return string;
    }
  };

  static cdnUrl = (url) => `https://cdn.bwtexperiences.com/${url}`;

  static numberRange = (start, end) =>
    new Array(end - start).fill(0).map((_, i) => i + start);

  static convertToIndianCurrency = (number) => {
    if (!process.browser) return;
    let country = "IN";
    country = Lockr.get("country");
    // get currency from local storage
    let currency = "INR";
    let currencySymbol = "₹";
    if (country === "US") {
      currency = "USD";
      currencySymbol = "$";
    }
    if (country === "US") {
      return Math.round(number / 85).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } else {
      let x = number;
      if (!x) x = 0;
      x = x.toString();
      let lastThree = x.substring(x.length - 3);
      let otherNumbers = x.substring(0, x.length - 3);
      if (`${otherNumbers}` !== "") lastThree = "," + lastThree;
      return `₹${otherNumbers.replace(
        /\B(?=(\d{2})+(?!\d))/g,
        ","
      )}${lastThree}`;
    }
  };

  static arrayCheck = (arr: any): Array<any> => {
    if (Array.isArray(arr) && arr.length) return arr;
    return [];
  };

  static copyText = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    Utils.successMessage("Copied to clipboard");
  };

  static constructImageUrl = (
    dimension = "600x400",
    format: string,
    url: string,
    quality?: number
  ) =>
    `https://img.bwtexperiences.com/unsafe/${dimension}/filters:format(${format}):quality(${
      quality || 80
    })/${url}`;

  static shuffleArray = (arr: any[]): any[] =>
    Utils.arrayCheck(arr).sort(() => Math.random() - 0.5);

  static getPageUrl = (router: NextRouter) =>
    `https://www.bwtexperiences.com${router.asPath}`;

  static isMobileView = () => process.browser && window.innerWidth < 768;
}

/**
 * This Function will return empty object on server side and
 * query params as an object on client side. Use it cautiously.
 */
export const getParams = (search?: string): ParsedUrlQueryInput => {
  if (!process.browser) {
    console.error("Do Not call this function on server side!!!");
    return {};
  }

  let params = {};

  new URLSearchParams(search || window.location.search).forEach((val, key) => {
    params[key] = val;
  });

  return params;
};
