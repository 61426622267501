import { Dispatch, useCallback, useReducer, useState } from "react";
import {
  FetchCallbackType,
  useDelete,
  useGet,
  usePost,
} from "services/api/useFetch";

import Analytics from "utils/Analytics";
import { setToken } from "./CookieUtils";
import { useAuth } from "services/AuthService/AuthProvider";

export const useStateReducer = <P extends object>(
  initialState: P
): [P, Dispatch<Partial<P>>] =>
  useReducer((state: P, action) => ({ ...state, ...action }), initialState);

export const useToggle = (initialState = false): [boolean, () => void] => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState((state) => !state), []);
  return [state, toggle];
};

export const useGetUser = (
  initialLoading?: boolean,
  onSuccess?: FetchCallbackType,
  onError?: FetchCallbackType
) => {
  return useGet(
    "v1/users/me/",
    {},
    {
      initialLoading,
      onSuccess,
      onError,
    }
  );
};

export const useLogin = (onSuccess?: Function, onError?: FetchCallbackType) => {
  const { getUserData } = useAuth();
  const [, , login] = usePost("v1/users/", null, {
    onSuccess: (res) => {
      setToken(res.token);
      getUserData();
      onSuccess?.(res);
      return res;
    },
    onError,
  });

  return login;
};

export const useLogout = () => {
  const { onLogout } = useAuth();

  const [, , logout] = useDelete("v1/users/me/", null, {
    beforeFetch: () => {
      Analytics.track("user_logout");
    },
    onSuccess: onLogout,
  });

  return logout;
};
